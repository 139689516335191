import React from "react"
import SEO from "../components/seo"
import HomeSplash from "../images/svg/splash.svg"
import "../styles/style.css"

/**
 *
 *
   background-image: url(/static/players-2369e22….png);
   background-position: center 12%;
   background-repeat: no-repeat;
   background-color: transparent;
   background-size: 47%;
   mix-blend-mode: luminosity;
   width: 100%;
 *
 *
 * **/


function HomeLogo(props) {
  return (
    <img className={"logo-splash"} src={require("../images/svg/logo-splash.svg")} alt={""}/>
  )
}

function Instgram(props) {
  return (
    <img src={require("../images/svg/instagram.svg")} alt={"istagram"}/>
    )
}

function What(props) {
  return (
    <img src={require("../images/svg/snpachat.svg")} alt={"istagram"}/>
  )
}

function Fb(props) {
  return (
    <img src={require("../images/svg/fb.svg")} alt={"istagram"}/>
  )
}


const IndexPage = () => (
    <div className={"home-container"}>
        <SEO title="Accueil"/>

        <div className={"home-header-splash"}>
          <div className={"home-title"}>
            <span style={{color:"#27A9FF"}}>Atterissage</span>
            <span style={{color:"#FF0F66",marginLeft:"1rem"}}> le 20 mars</span>
          </div>
          <div className={"home-logo"}>
            <HomeLogo width={"30%"}/>
          </div>
        </div>

      <div className={"home-body"}>
        <img src={require("../images/svg/splash.svg")} style={{width:"100%",height:'100%' }} alt={""}/>
      </div>

      <div className={"home-footer"}>
        <div className={"home-footer-social"}>
          <a href={"#"}>
            <Instgram/>
          </a>
          <a>
            <What/>
          </a>
          <a>
            <Fb/>
          </a>
        </div>
        <button className={"btn-demo"}>Demandez une démonstration</button>
        <span>contact@ineo360.fr</span>
      </div>

    </div>
);

export default IndexPage
